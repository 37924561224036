import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { Button, Divider, IconButton, Menu, MenuItem, Modal } from "@material-ui/core";
import { getPredefinedColor } from "utils/users";
import { PhotoCamera } from "@material-ui/icons";
import UserProfileContent from "components/UserProfile/UserProfileContent/UserProfileContent";
import ProfileModal from "components/UserProfile/ProfileModal/ProfileModal";

import useStyles from "styles/appStyles";
import { updateUser } from "api/user";
import { getUser } from "state/reducers/auth";
import { redirectLogout } from "utils/auth";
import { useAuth } from "state/selector";
import { useNavigate } from "react-router";
import { NL_EXTENSION_EVENTS } from "utils/constants";
import { sendExtensionMessage } from "utils/extension";

const UserProfileMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const userProfilePicture = user.profile_picture;
  const profilePhotoInputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const onChangeProfilePhotoFile = async e => {
    e.stopPropagation();
    e.preventDefault();
    const formData = new FormData();

    if (e.target.files?.length > 0) {
      const file = e.target.files[0];
      formData.append("profile_picture", file, file.name);
      await updateUser(user.id, formData);
      dispatch(getUser());
    }
  };

  const handleProfilePhotoClick = () => profilePhotoInputRef.current?.click();

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleLogOut = () => redirectLogout();

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button onClick={handleClick}>
        <div
          className={classes.profilePhoto}
          style={{
            background: `${userProfilePicture ? `url(${userProfilePicture})` : getPredefinedColor(user.id)}`,
          }}
        >
          {!userProfilePicture}
        </div>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.userProfileMenu}
      >
        <input
          type="file"
          ref={profilePhotoInputRef}
          style={{ display: "none" }}
          accept="image/png, image/jpeg"
          onChange={onChangeProfilePhotoFile}
        />

        <div
          className={cn(classes.profilePhoto, classes.profileMenuPhoto)}
          style={{
            background: `${userProfilePicture ? `url(${userProfilePicture})` : getPredefinedColor(user.id)}`,
          }}
        >
          {!userProfilePicture}
          <IconButton
            color="primary"
            component="span"
            className={classes.profilePhotoIconButton}
            onClick={handleProfilePhotoClick}
          >
            <PhotoCamera />
          </IconButton>
        </div>
        <MenuItem className={cn(classes.menuHeader, classes.profileMenuUserName)}>
          {user.name || user.username}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            if (!sendExtensionMessage({ type: NL_EXTENSION_EVENTS.NEW_LANTERN_HANGING_PROTOCOL_OPENED })) {
              navigate("/hanging-protocols");
            }
            handleClose();
          }}
        >
          Hanging Protocols
        </MenuItem>
        <MenuItem onClick={() => setOpenModal(true)}>Profile</MenuItem>
        <MenuItem
          onClick={() => {
            if (!sendExtensionMessage({ type: NL_EXTENSION_EVENTS.NEW_LANTERN_DASHBOARD_OPENED })) {
              navigate("/dashboard");
            }
            handleClose();
          }}
        >
          Dashboard
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogOut}>Sign out</MenuItem>
      </Menu>
      <ProfileModal openPopup={openModal}>
        <UserProfileContent handleClose={handleModalClose} />
      </ProfileModal>
    </>
  );
};

export default UserProfileMenu;
